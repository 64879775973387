<template>
    <div>
        <div class="d-flex flex-wrap">
            <div class="col-12 col-lg-2">
                <router-link
                    to="/app/images"
                    class="rounded-circle bg-info text-center p-2"
                >
                    <span>🏠</span>
                </router-link>
            </div>
            <div class="col-12 col-lg-8 text-center">
                <h1 class="h2">Subir y registrar imagen</h1>
            </div>
        </div>
        <div id="info-container" class="d-flex flex-wrap">
            <div
                class="offset-lg-7 col-lg-5 col-12 mt-2 d-flex align-items-center justify-content-between justify-content-lg-end"
            >
                <small for="swDateBetween">
                    ¿ Necesitas ayuda para crear las imágenes ?
                </small>
                <vs-button
                    icon
                    border
                    :active="enableHelp"
                    @click="enableHelp = !enableHelp"
                >
                    ℹ️
                </vs-button>
            </div>
        </div>
        <div v-if="enableHelp" class="d-flex flex-wrap">
            <div class="col-10 mx-auto rounded border border-success py-4">
                <ul class="list-group">
                    <li class="py-2 px-1">
                        1. Llena el formulario con los datos de la imagen.
                    </li>
                    <li class="py-2 px-1">
                        2. Dale click en el botón con el icono de carga(⬆️)
                    </li>
                    <li class="py-2 px-1">
                        3. Selecciona el archivo, asegurate que tenga las
                        dimensiones adecuadas segun el tipo de imagen
                    </li>
                    <li class="py-2 px-1">
                        4. El sistema te va a preguntar si estas seguro de la
                        carga de la imagen, responde si. En caso de responder si
                        y haber cargado la imagen erronea. Contactate con el
                        administrador (Nicolas).
                    </li>
                    <li class="py-2 px-1">
                        4. Cuando la imagen se haya cargado de forma adecuada,
                        el sistema te habilita el botón Crear imagen, verifica
                        los datos y dale click en el botón.
                    </li>
                    <li class="py-2 px-1">
                        &1 Si selecciona response como tipo de imagen recuerda
                        subir una imagen con la misma extensión.
                    </li>
                    <li class="py-2 px-1">
                        &2 El nombre de la imagén será analizado buscando una
                        entidad, si el sistema encuentra resultados la imagen
                        será asociada a dicha entidad. Para referencias el
                        nombre de la imágen debe tener el código de barras y
                        para líneas, categorías, marcas, tiendas o bodegas debe
                        de tener el slug. Para ajustar el orden de la imagen use
                        el formato de archivos repetidos "nombre
                        (número).extensión" ejemplo: 7819217 (1).jpeg
                    </li>
                </ul>
            </div>
        </div>
        <div class="row px-4 py-4">
            <div class="col-12 col-lg-6 mt-4">
                <div class="form-group py-2">
                    <vs-input
                        id="alt"
                        v-model="alt"
                        type="text"
                        name="altImages"
                        :disabled="isLoading"
                        label="Alt imagen"
                        state="success"
                        placeholder="Nombre de la imagen"
                        class="input-theme"
                        :class="!validAlt ? '' : 'border-custom'"
                        aria-label="Alt"
                        icon-after
                        @blur="introValidators.alt = true"
                    >
                        <template #icon> 📷</template>
                        <template #message-danger>
                            <div v-show="!validAlt && introValidators.alt">
                                Ingrese un alt sin simbolos raros entre 8 y 64
                                carácteres.
                            </div>
                        </template>
                    </vs-input>
                </div>
            </div>
            <div class="col-12 col-lg-6 mt-4">
                <div class="form-group py-2">
                    <sizes-images-all-selector v-model="size" />
                </div>
            </div>
            <div class="col-12 my-3 d-flex align-items-center">
                <span>
                    Asociar imagen a una entidad (Ver ayuda &2) : &nbsp;
                </span>
                <vs-radio
                    v-for="(value, key) in $data.$entitiesUploadImage"
                    :key="key"
                    v-model="entity"
                    class="mt-1"
                    :val="key"
                >
                    {{ value }}
                </vs-radio>
            </div>
        </div>

        <div v-if="showUpload" class="row">
            <div
                v-for="(item, index) in files"
                :key="index"
                class="col-12 col-md-6 col-lg-4 mx-auto"
            >
                <div class="col-12 text-center">
                    <span>
                        Tamaño {{ item.strict ? "estricto" : "sugerido" }}
                        <span class="text-primary">
                            {{ item.width }} X {{ item.height }}
                        </span>
                    </span>
                </div>

                <div
                    v-if="item.uploaded"
                    class="col-12 d-flex justify-content-center"
                >
                    <img
                        loading="lazy"
                        :src="item.urlOriginal"
                        alt="Imagen subida"
                        class="w-64"
                    />
                </div>
                <div
                    v-else
                    class="col-12 my-2 d-flex justify-content-center"
                    @click="clickButton"
                >
                    <label
                        class="w-64 d-flex flex-column align-items-center px-4 py-6 btn btn-success rounded text-uppercase"
                    >
                        <img
                            src="@/assets/images/upload.svg"
                            height="20"
                            width="20"
                            alt="upload img"
                        />
                        <span class="mt-2 text-base leading-normal">
                            {{
                                item.uploaded
                                    ? item.name
                                    : "Seleccione un archivo"
                            }}
                        </span>
                        <input
                            ref="uploader"
                            type="file"
                            :accept="`image/${ext}`"
                            class="d-none"
                            :disabled="!validAlt"
                            @input="uploadButton($event, index)"
                        />
                    </label>
                </div>
            </div>
        </div>

        <div class="row d-flex justify-content-center mt-3">
            <vs-button
                gradient
                :active="validInfo"
                :disabled="!validInfo"
                size="large"
                @click="createImageComponent"
            >
                Crear imagen
            </vs-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { entitiesUploadImage } from "@/common/fields/entities-paths";
import { sizesImagesAll } from "@/common/fields/sizes-images";
import { altRegex } from "@/common/lib/regex";

import SizesImagesAllSelector from "@/components/utils/SizesImagesAllSelector.vue";

export default {
    name: "ImagesNew",
    components: { SizesImagesAllSelector },
    data: () => ({
        id: 0,
        enableHelp: false,
        entity: "",
        ext: "*",
        alt: "",
        urlOriginal: "",
        url: "",
        size: "squareImage",
        isLoading: false,
        showUpload: true,
        file: {
            name: "undefined",
            size: "11111"
        },
        files: [
            {
                size: "squareImage",
                url: "",
                urlOriginal: "",
                name: "",
                fileSize: 0,
                uploaded: false,
                height: 1200,
                width: 1200,
                strict: true
            }
        ],
        introValidators: { alt: false },
        uploader: null,
        $entitiesUploadImage: entitiesUploadImage
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"]),
        validAlt() {
            return altRegex.test(this.alt);
        },
        validInfo() {
            return this.files.every((item) => item.uploaded) && this.validAlt;
        }
    },
    watch: {
        size(value) {
            const aux = sizesImagesAll.find((item) => item.size === value);
            this.files = aux.sizes.map((item) => ({
                ...item,
                url: "",
                name: "",
                fileSize: 0,
                uploaded: false
            }));
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        await verifyPageScope("image:Create", "/app");
    },
    methods: {
        ...mapActions("images", [
            "getEndPointUploadImage",
            "uploadImage",
            "createImage"
        ]),
        async clickButton() {
            if (!this.validateData()) {
                return this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Completa los campos",
                    text: "Antes de subir la image verifica haber llenado el campo alt y seleccionado el tipo de imagen.",
                    icon: "info"
                });
            }
        },
        async uploadButton(event, index) {
            const auxFile = event.target.files || event.dataTransfer.files;
            if (!auxFile.length) return;
            this.file = auxFile[0];
            const fileSize = this.file.size / 1048576;
            if (fileSize > 20) {
                this.$refs.uploader.value = null;
                return this.$swal.fire({
                    icon: "info",
                    title: `Archivo es demasiado pesado: ${fileSize} MB`
                });
            }
            const fileTemp = {
                ...this.files[index],
                name: auxFile[0].name,
                fileSize: auxFile[0].size,
                uploaded: false
            };

            const dialogResult = await this.$swal.fire({
                title: `Aseguras que ${fileTemp.name} es la imagen es adecuada ?`,
                text: `La imagen debe tener las siguientes medidas ${fileTemp.width}X${fileTemp.height}`,
                background: this.backgroundColor,
                icon: "info",
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Si",
                cancelButtonText: "No",
                confirmButtonColor: "#02913f",
                cancelButtonColor: "#f07325",
                allowOutsideClick: false
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({ type: "circles" });
                try {
                    const { id, url, urlOriginal, urlSigned } =
                        await this.getEndPointUploadImage({
                            fileName: fileTemp.name,
                            fileSize: fileTemp.fileSize,
                            size: fileTemp.size,
                            alt: this.alt,
                            entity: this.entity,
                            ...(this.id ? { id: this.id } : null)
                        });
                    this.id = id;
                    fileTemp.url = `${url}`;
                    fileTemp.urlOriginal = `${urlOriginal}`;
                    this.url = url.replace("original", "large");
                    if (
                        fileTemp.size === "smallImage" ||
                        fileTemp.size === "normalImage"
                    ) {
                        this.url = this.url
                            .replace("normal", "large")
                            .replace("small", "large");
                    }
                    await this.uploadImage({
                        url: urlSigned,
                        file: this.file
                    });
                    fileTemp.uploaded = true;
                    this.ext = this.file.name.split(".").pop();
                } catch (error) {
                    this.$refs.uploader.value = null;
                    this.loaderInstance.close();
                    await this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                    fileTemp.uploaded = false;
                    this.file = { name: "undefined", size: "11111" };
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            } else {
                this.$refs.uploader.value = null;
                fileTemp.uploaded = false;
            }
            const files = [...this.files];
            files[index] = { ...fileTemp };
            this.files = [...files];
        },
        async createImageComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const result = await this.createImage({
                    id: this.id,
                    url: this.url,
                    alt: this.alt,
                    size: this.size
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                this.isLoading = false;
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: `¿ Deseas continuar subiendo imágenes o regresar al inicio ? `,
                    text: `Imagen creada con el ID: ${result.id} `,
                    icon: "success",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp"
                    },
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Regresar",
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#113ea2",
                    allowOutsideClick: false,
                    showCloseButton: true
                });
                if (!dialogResult.isConfirmed) {
                    this.$router.push("/app/images");
                }
                this.introValidators.alt = false;
                this.file = { name: "undefined", size: "11111" };
                this.alt = "";
                const aux = sizesImagesAll.find(
                    (item) => item.size === this.size
                );
                this.files = aux.sizes.map((item) => ({
                    ...item,
                    url: "",
                    name: "",
                    fileSize: 0,
                    uploaded: false
                }));
                this.showUpload = false;
                setTimeout(() => {
                    this.showUpload = true;
                    location.replace(location.href);
                }, 100);
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        validateData() {
            this.introValidators = { alt: false };
            return this.validAlt && !!this.size;
        }
    }
};
</script>
