<template>
    <div id="list-sizesImagesAll-component" class="w-100">
        <vs-select
            v-model="imageSize"
            label="Tamaños de imágenes"
            name="itemCurrent"
            :state="stateInputDark"
            class="w-100 mw-100 border-custom"
            :disabled="!enabled"
            @input="onInput"
        >
            <vs-option
                v-for="item in $data.$sizesImagesAll"
                :key="item.size"
                :label="item.title"
                :value="item.size"
            >
                {{ item.title }}
            </vs-option>
        </vs-select>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { sizesImagesAll } from "@/common/fields/sizes-images";

export default {
    name: "SizesImagesSelector",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => "squareImage",
            type: String,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        imageSize: "",
        $sizesImagesAll: sizesImagesAll
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark", "backgroundColor"])
    },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        onInput(value) {
            this.$emit("change", value);
        },
        setInput(value) {
            this.imageSize = value;
        }
    }
};
</script>
